
const MESSAGE_TYPE = {
	TEXT: 0,
	IMAGE:1,
	FILE:2,
	AUDIO:3,
	VIDEO:4,
	RT_VOICE:5,
	RT_VIDEO:6,
	AUTOREPLY:9,
	RECALL:10,
	READED:11,
	RECEIPT:12,
	TIP_TIME:20,
	TIP_TEXT:21,
	TIP_OWNER:22,
	TIP_MUZZLE:23,
	LOADDING:30,
	RTC_CALL_VOICE: 100,
	RTC_CALL_VIDEO: 101,
	RTC_ACCEPT: 102,
	RTC_REJECT: 103,
	RTC_CANCEL: 104,
	RTC_FAILED: 105,
	RTC_HANDUP: 106,
	RTC_CANDIDATE: 107
}

const RTC_STATE = {
	FREE: 0, //空闲，可以被呼叫
	WAIT_CALL: 1, // 呼叫后等待
	WAIT_ACCEPT: 2, // 被呼叫后等待
	ACCEPTED: 3, // 已接受聊天，等待建立连接
	CHATING:4  // 聊天中
}

const TERMINAL_TYPE = {
	WEB: 0,
	APP: 1
}

const MESSAGE_STATUS = {
	UNSEND: 0,
	SENDED: 1,
	RECALL:2,
	READED:3
}


export {
	MESSAGE_TYPE,
	RTC_STATE,
	TERMINAL_TYPE,
	MESSAGE_STATUS
}
