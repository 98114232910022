<template>
	<el-scrollbar v-show="show" ref="scrollQrBox" class="quick-reply-choose"
		:style="{'left':pos.x+'px','top':pos.y-420+'px'}">
		<div class="chat-group-member" v-for="(qr) in qrs" :key="qr.id">
		    <div class="member-name"  @click="onSelectQr(qr)" >{{ qr.reply }}</div>
		</div>
	</el-scrollbar>
</template>

<script>
	export default {
		name: "chatQrBox",
		props: {
			searchText: {
				type: String,
				default: ""
			},
			qrs: {
				type: Array
			}
		},
		data() {
			return {
				show: false,
				pos: {
					x: 0,
					y: 0
				},
				activeIdx: 0,
				showQrs: []
			};
		},
		methods: {
			init() {
				this.$refs.scrollQrBox.wrap.scrollTop = 0;
				this.showQrs = [];
				this.qrs.forEach((q) => {
					if ( q.reply.startsWith(this.searchText)) {
						this.showQrs.push(q);
					}
				})
				this.activeIdx = this.showQrs.length > 0 ? 0: -1;
			},
			open(pos) {
				this.show = true;
				this.pos = pos;
				this.init();
			},
			close() {
				this.show = false;
			},
			moveUp() {
				if (this.activeIdx > 0) {
					this.activeIdx--;
					this.scrollToActive()
				}
			},
			moveDown() {
				if (this.activeIdx < this.qrs.length - 1) {
					this.activeIdx++;
					this.scrollToActive()
				}
			},
			select() {
				if (this.activeIdx >= 0) {
					this.onSelectQr(this.qrs[this.activeIdx])
				}
				this.close();
			},
			scrollToActive() {
				if (this.activeIdx * 35 - this.$refs.scrollQrBox.wrap.clientHeight > this.$refs.scrollQrBox.wrap.scrollTop) {
					this.$refs.scrollQrBox.wrap.scrollTop += 140;
					if (this.$refs.scrollQrBox.wrap.scrollTop > this.$refs.scrollQrBox.wrap.scrollHeight) {
						this.$refs.scrollQrBox.wrap.scrollTop = this.$refs.scrollQrBox.wrap.scrollHeight
					}
				}
				if (this.activeIdx * 35 < this.$refs.scrollQrBox.wrap.scrollTop) {
					this.$refs.scrollQrBox.wrap.scrollTop -= 140;
					if (this.$refs.scrollQrBox.wrap.scrollTop < 0) {
						this.$refs.scrollQrBox.wrap.scrollTop = 0;
					}
				}
			},
			onSelectQr(qr) {
				this.$emit("select", qr);
				this.show = false;
			}
		},
		computed: {
		},
		watch: {
			searchText: {
				handler(newText, oldText) {
					this.init();
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.quick-reply-choose {
		position: fixed;
		width: 600px;
		height: 400px;
		border: 1px solid #b4b4b4;
		border-radius: 5px;
		background-color: #f5f5f5;
		box-shadow: 0px 0px 10px #ccc;
	}
	.chat-group-member {
        display: flex;
        margin-bottom: 1px;
        position: relative;
        padding: 0 5px;
        align-items: center;
        background-color: #fafafa;
        white-space: nowrap;
        box-sizing: border-box;
        overflow-y: auto;

        &:hover {
            background-color: #eeeeee;
        }

        &.active {
            background-color: #eeeeee;
        }

        .member-name {
            padding-left: 10px;
            height: 100%;
            text-align: left;
            white-space: normal;
            overflow: hidden;
            font-size: 18px;
            font-weight: 600;
        }
    }
</style>