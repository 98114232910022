<template>
   <div/>
</template>

<script>
	export default {
		created() {
		   this.loginForm.userId = this.$route.query.userId
           this.loginForm.userName = this.$route.query.userName
           this.loginForm.businessId = this.$route.query.businessId
           this.loginForm.businessKey = this.$route.query.businessKey
           this.loginForm.businessCode = this.$route.query.businessCode
           this.loginForm.sex = this.$route.query.sex
           this.loginForm.levels = this.$route.query.levels
           this.loginForm.language = this.$route.query.language
           this.loginForm.money = this.$route.query.money
           this.$http({url: "/kefuLogin",
                method: 'post',
                data: this.loginForm
           }).then((data) => {
                // 保存token
                sessionStorage.setItem("accessToken", data.accessToken);
                sessionStorage.setItem("refreshToken", data.refreshToken);
                sessionStorage.setItem("loginType", "kefu");
                sessionStorage.setItem("kefuId", data.kefuId);
                sessionStorage.setItem("kefuName", data.kefuName);
                let item = localStorage.getItem("chats-" + data.userId);
                if(!item){
                    let chat = {
                        type: 'PRIVATE',
                        targetId: data.kefuId,
                        showName: data.kefuName,
                        headImage: data.headImage,
                    };
                    this.$store.commit("openChat", chat);
                }

                //this.$message.success("登陆成功");
                this.$router.push("/kefu");
           })
		},
		data() {
			return {
                loginForm: {
                	terminal: this.$enums.TERMINAL_TYPE.APP,
                	businessId: '',
                	businessKey: '',
                  businessCode: '',
                	userId: '',
                	userName: '',
                	sex: '',
                    levels: '',
                    language: '',
                    money: ''
                },
			}
		}
	}
</script>

<style lang="scss">

</style>