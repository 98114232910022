<template>
  <el-container class="chat-page">
     <el-container class="chat-box">
			<chat-box v-if="chatStore.activeChat" :chat="chatStore.activeChat" :iskefu="true"  ></chat-box>
     </el-container>
  </el-container>
</template>

<script>
	import ChatBox from "../components/chat/ChatBox.vue";

	export default {
		name: "kefu",
		components: {
			ChatBox
		},
		created() {
		    this.init();
		},
		mounted() {

           console.log(this.$store.state.chatStore);
		},
		data() {
			return {
				searchText: "",
				messageContent: ""
			}
		},
		methods: {
		    init() {
            		this.$store.dispatch("kefuload").then(() => {
            		    // ws初始化
            		    this.$wsApi.connect(process.env.VUE_APP_WS_URL, sessionStorage.getItem("accessToken"));
            		    this.$wsApi.onConnect(() => {
            		        // 加载离线消息
            		        this.pullPrivateOfflineMessage(this.$store.state.chatStore.privateMsgMaxId);
            		    });
            			this.$wsApi.onMessage((cmd, msgInfo) => {
            						if (cmd == 2) {
            							// 关闭ws
            							this.$wsApi.close(3000)
            							// 异地登录，强制下线
            							this.$alert("您已在其他地方登陆，将被强制下线", "强制下线通知", {
            								confirmButtonText: '确定',
            								callback: action => {
            									location.href = "/";
            								}
            							});

            						} else if (cmd == 3) {
            							// 插入私聊消息
            							this.handlePrivateMessage(msgInfo);
            						}
            					});
            					this.$wsApi.onClose((e) => {
            						console.log(e);
            						if (e.code != 3000) {
            							// 断线重连
            							this.$message.error("连接断开，正在尝试重新连接...");
            							this.$wsApi.reconnect(process.env.VUE_APP_WS_URL, sessionStorage.getItem(
            								"accessToken"));
            						}
            					});
            				}).catch((e) => {
            					console.log("初始化失败", e);
            				});

            },
            pullPrivateOfflineMessage(minId) {
              				this.$http({
              					url: "/message/private/pullOfflineMessage?minId=" + minId,
              					method: 'get'
              				});
            },
            handlePrivateMessage(msg) {
              				// 消息加载标志
              				if (msg.type == this.$enums.MESSAGE_TYPE.LOADDING) {
              					this.$store.commit("loadingPrivateMsg", JSON.parse(msg.content))
              					return;
              				}
              				// 消息已读处理，清空已读数量
              				if (msg.type == this.$enums.MESSAGE_TYPE.READED) {
              					this.$store.commit("resetUnreadCount", {
              						type: 'PRIVATE',
              						targetId: msg.recvId
              					})
              					return;
              				}
              				// 消息回执处理,改消息状态为已读
              				if (msg.type == this.$enums.MESSAGE_TYPE.RECEIPT) {
              					this.$store.commit("readedMessage", {
              						friendId: msg.sendId
              					})
              					return;
              				}
              				// 标记这条消息是不是自己发的
              				msg.selfSend = msg.sendId == this.$store.state.userStore.userInfo.id;
              				// 好友id
              				let friendId = msg.selfSend ? msg.recvId : msg.sendId;
              				this.loadFriendInfo(friendId).then((friend) => {
              					this.insertPrivateMessage(friend, msg);
              				})
            },
            loadFriendInfo(id) {
            				return new Promise((resolve, reject) => {
            					let friend = this.$store.state.friendStore.friends.find((f) => f.id == id);
            					if (friend) {
            						resolve(friend);
            					} else {
            						this.$http({
            							url: `/friend/find/${id}`,
            							method: 'get'
            						}).then((friend) => {
            							this.$store.commit("addFriend", friend);
            							resolve(friend)
            						})
            					}
            				});
            },
            insertPrivateMessage(friend, msg) {
            				let chatInfo = {
            					type: 'PRIVATE',
            					targetId: friend.id,
            					showName: friend.nickName,
            					headImage: friend.headImage
            				};
            				// 打开会话
            				this.$store.commit("openChat", chatInfo);
            				// 插入消息
            				this.$store.commit("insertMessage", msg);
            				// 播放提示音
            				if (!msg.selfSend && msg.status != this.$enums.MESSAGE_STATUS.READED) {
            					this.playAudioTip();
            				}
            },
            playAudioTip() {
            				if (new Date().getTime() - this.lastPlayAudioTime > 1000) {
            					this.lastPlayAudioTime = new Date().getTime();
            					let audio = new Audio();
            					let url = require(`@/assets/audio/tip.wav`);
            					audio.src = url;
            					audio.play();
            				}

            }
		},
		computed: {
			chatStore() {
				return this.$store.state.chatStore;
			}
		}
	}
</script>

<style lang="scss">

</style>